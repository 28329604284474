.PopupInputDialogWrap {
    width: 30%;
    min-height: 100px;
    background-color: var(--input-bg-hover-color);
    border-radius: 4px;
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.49);
    font-size: 14px;
    padding: 0 20px 20px 20px;
}


.PopupInputDialogWrap input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    background: darkolivegreen;
    border-radius: 1px;
}

.PopupInputDialogWrap input[type=range]::-webkit-slider-thumb {
    box-shadow: 0 0 1px var(--input-border-color);
    border: 2px solid var(--input-border-color);
    height: 15px;
    width: 15px;
    border-radius: 10px;
    background: var(--input-color);
    margin-top: -7px;
}


.PIDTextInput {
    border-radius: 4px;
    height: 23px;
    border: 1px solid #a9a9a9;
    background-color: hsla(0,0%,100%,.38);
    padding-left: 4px;
    color: #c3c3c3;
    margin-top: 2px;
    margin-bottom: 10px;
    width: 100%;
}

.PIDOkButton {
    min-width: 100px;
    text-align: center;
    font-size: 13px;
    padding: 8.5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    background: var(--input-bg-hover-color);
    color: hsla(0,0%,80.4%,.79);
}

.PIDLanguageIconBlock {
    display: inline;
    cursor: pointer;
    color: var(--info);
}






