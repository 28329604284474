.AvatarItemContainer {
 height: 50px;
 display: flex;
 align-items: center;
 font-size: 12px;
}

.AIImgBlock {
 width: 50px;
 height: 50px;
 border-radius: 3px;
 overflow: hidden;
 border: 1px solid;
 float: left;
 margin-right: 8px;
}

.AIImgBlock img {
 width: 100%;
 height: 100%;
}

.LinkItemContainer {
 font-size: 12px;
 display: flex;
 align-items: center;
}

.LIIconBlock {
 float: left;
 margin: 0 11px 0 6px;
 color: var(--input-border-color);
}

.LITextBlock {
 font-size: 10px;
 width: calc(100% - 47px);
 overflow: hidden;
}
