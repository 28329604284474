.AvatarEditorBlock {
    /*width: 80%;*/
    height: 50%;
    text-align: center;
    background-color: var(--object-bg-color);
}

.ReactCropBlock {
    height: calc(100% - 40px);
}

.ReactCropBlock div:first-child {
    height: 100%;
}

.AEHeader {
    font-size: 20px;
    padding-top: 5px;
    font-weight: 300;
    display: inline-block;
    width: 100%;
    background-color: var(--object-author-color);
    color: #7d5c0a;
    border-radius: 5px 5px 0 0;
    height: 35px;
    position: relative;
}

.AEFooter {
    border-radius: 0 0 5px 5px;
    display: inline-block;
    width: 100%;
    min-width: 320px;
    background-color: var(--object-author-color);
    height: 35px;
    margin-top: -3px;
}

.AEButton {
    background-color: var(--input-border-color);
    color: var(--input-bg-color);
    border: 1px solid var(--object-author-hightlight);
    padding: 4px 10px;
    margin: 4px 4%;
    border-radius: 4px;
    font-size: 13px;
}

.AEButton i {
    margin-right: 4px;
}

.AvatarEditorBlock .closeButton {
    right: 3px;
    font-size: 17px;
    top: 9px;
}