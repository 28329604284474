.APContainer {
    position: fixed;
    right: -10px;
    bottom: 0;
    padding: 7px;
    height: 40px;
    background-color: #024b44d4;
    z-index: 600;
}

.APContainer i {
    margin: 0 7px;
    font-size: 27px;
    color: var(--input-color);
}





