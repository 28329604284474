.SpeekEditorContainer {
    width: 700px;
    height: 560px;
    background-color: var(--object-bg-color);
    position: relative;
    border-radius: 4px;
    box-shadow: 3px 2px 10px #0000002e;
    font-size: 14px;
}

.SEHeader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    padding: 0 20px 0 20px;
    font-size: 16px;
}

.SELeft {
    position: absolute;
    left: 0;
    top: 65px;
    width: 70%;
    height: calc(100% - 140px);
    padding: 5px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.SERight {
    position: absolute;
    right: 0;
    top: 65px;
    width: 30%;
    height: calc(100% - 140px);
    padding: 5px 20px;
    font-size: 11px;
    line-height: 1.5;
    overflow-y: scroll;
}

.SEFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
}

.SpeekEditorContainer input, .SpeekEditorContainer select, .SpeekEditorContainer textarea {
    border-radius: 4px;
    height: 20px;
    border: 1px solid darkgrey;
    background-color: #ffffff61;
    padding-left: 4px;
    color: #c3c3c3;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 10px;
}

.SpeekEditorContainer textarea {
    height: 75px;
    width: 100%;
}

.SEDescription {
    width: calc(100% - 20px)!important;
}

.SpeekEditorContainer u {
    color: white;
    display: inline-block;
    margin-bottom: 3px;
    text-decoration: unset;
}

.SERight .OptionKey {
    width: 90%;
}

.SERight input {
    margin: unset;
    height: 13px;
}

.SERight .OptionValue {
    width: 10%;
}

.SESave {
    min-width: 100px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    text-align: center;
    /*font-weight: bold;*/
    font-size: 13px;
    padding: 8.5px 10px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    background: var(--input-bg-hover-color);
    color: #cdcdcdc9;
}

.SESave i {
    margin-right: 3px;
    font-size: 12px;
}

.SESmallInfo {
    display: block;
    margin-top: 5px;
    color: var(--btn-bg-color);
    font-size: 12px;
}

.SESmallInfo .likeBtn {
    margin: 0 10px;
}

.SEPersonObjectBlock {
    float: right;
    padding: 10px 20px 0 20px;
    margin-top: 10px;
    margin-right: 30%;
}

.SEPersonObjectBlock .ObjectSelectorBlock {
    float: right;
    margin: 0 0 0 10px;
    width: 35px;
    height: 35px;
}

.SEPersonObjectBlock .ObjectSelectorBlock i {
    margin-top: 11px;
}

.SEPersonObjectBlock .ObjectSelectorText {
    font-size: 13px;
    padding-top: 3px;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.rotate {
    -webkit-animation: rotation 2s infinite ease-in-out;
}

.SEAudioControl {
    float: left;
    margin-right: 3px;
    text-align: center;
    font-size: 13px;
    padding: 10px;
    border-radius: 5px;
    background: var(--input-bg-hover-color);
    color: #cdcdcdc9;
    width: 40px;
}

.SEUploadAudio {
    flex-grow: 10;
    width: max-content;
    text-align: center;
    font-size: 13px;
    padding: 10px;
    border-radius: 5px;
    /* border: 1px solid var(--input-border-color); */
    background: var(--input-bg-hover-color);
    color: #cdcdcdc9;
}

.SEUploadAudio i {
    margin-left: 3px;
    /*font-size: 11px;*/
}

.MEAvatarChangeBtn {
    float: left;
    height: 50px;
    display: flex;
    align-items: center;
    /*width: calc(100% - 60px);*/
    font-size: 12px;
    text-align: center;
    padding: 10px 15px 10px 10px;
    border-radius: 5px;
    background: var(--input-bg-hover-color);
    color: #cdcdcdc9;
}

.MEAvatarChangeBtn i {
    margin: 0 10px 0 5px;
    font-size: 15px;
}

.MediaTagsEditorIcon {
    position: absolute;
    right: 30px;
    top: 20px;
}

select.MEBitrateSelect {
    width: 70px!important;
    height: 16px!important;
    font-size: 10px;
    background: var(--input-bg-hover-color);
    color: #cdcdcdc9;
    border: 0;
    margin-left: 5px;
}





