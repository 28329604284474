.AuthorPopupBlock {
    position: fixed;
    width: calc(25% - 20px);
    min-height: 50px;
    z-index: 1000;
    background: var(--input-bg-hover-color);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--object-bg-color);
    padding: 3px;
    box-shadow: 2px 2px 5px #0000006b;
    font-size: 11px;
    color: var(--input-color);
    cursor: default;
}

.AuthorPopupBlock i{
    color: var(--input-placeholder);
}

.APAvatarBlock {
    margin-right: 6px;
    border-radius: 4px;
    overflow: hidden;
    float: left;
    cursor: pointer;
}

.APAvatar {
    width: 40px;
    float: left;
}

.APInfoBlock {
    margin-top: -3px;
    font-size: 10px;
}

















