.PendingContainer {
    display: flex;
    flex-direction: row;
}

.PendingBlock {
    width: 50%;
}

.PendingBlock:first-of-type {
    border-right: 1px dashed gray;
}

.PendingBlock .ObjectListItem {
    width: 50%;
    margin: 10% 25%;
}

.PendingActionList {

}

.PendingActionBlock {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-bg-color);
    height: auto;
    margin-bottom: 5px;
    margin-left: 3px;
    border-radius: 5px;
    font-size: 12px;
    overflow: hidden;
}

.PendingActionAuthor {
    width: 80px;
}

.PendingActionContent {
    position: relative;
    width: calc(100% - 160px);
    border-right: 1px solid var(--input-border-color);
    min-height: 104px;
}

.PendingActionControl {
    width: 80px;
}

.PendingActionAuthor, .PendingActionContent, .PendingActionControl {
    float: left;
    height: 100%;
    padding: 3px;
}

.PendingActionComment {
    width: calc(100% - 77px);
    clear: both;
    padding: 3px 5px;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-bg-color);
    color: var(--input-color);
    border-radius: 4px;
    position: relative;
    margin-bottom: 2px;
    margin-top: 0;
    height: 74px;
}

.PendingActionComment small {
    font-weight: 500;
    color: cadetblue;
    position: absolute;
    right: 3px;
    bottom: 1px;
}

.PendingActionCommentSmall {
    width: 100%!important;
}

.PendingActionTagList {
    margin: 6px 0;
    overflow: hidden;
}

.PendingActionTagBlock {
    padding-left: 22px;
    padding-top: 1px;
    position: relative;
    min-height: 22px;
    background-color: #0b0b0b40;
    border: 1px solid #3e3e3ed1;
    border-radius: 5px;
    font-size: 11px;
    margin-top: 5px;
    cursor: default;
}

.PendingActionTagBlock img {
    position: absolute;
    left: 4px;
    top: 5px;
    width: 13px;
    opacity: 0.8;
}

.PendingActionControlOk, .PendingActionControlNo {
    float: left;
    border: 1px solid;
    width: 90%;
    margin: 5%;
    border-radius: 4px;
    text-align: center;
    height: 40%;
    padding: 10px!important;
    background-color: #dc3545;
}

.PendingActionControlOk i, .PendingActionControlNo i {
    font-size: 15px;
    color: white;
}

.PendingActionControlOk {
    background-color: #28a745;
}

.PendingActionAvatar {
    max-width: 74px;
    height: 74px;
    border: 1px solid var(--input-border-color);
    overflow: hidden;
    border-radius: 5px;
}

.PendingActionAvatar img {
    width: 100%;
    height: 100%;
}

.PendingActionAuthorName {
    width: 100%;
    padding: 3px;
    text-align: center;
}

.PendingDisabled {
    opacity: .65;
}






















