.DoubleSmallBlock {
    width: 23%;
    float: left;
    border: 1px solid var(--input-border-color);
    background-color: #3a76802e;
    margin: 1%;
    padding: 7px;
    border-radius: 5px;
    font-size: 12px;
    position: relative;
    overflow: hidden;
}

.DoubleSmallBlock input[type=text], .DoubleSmallBlock select , .DoubleSmallBlock textarea {
    border-radius: 4px;
    background-color: #ffffffc4;
    border: 0;
    width: calc(100% - 8px);
    padding: 3px 3px 3px 4px;
    font-size: 12px;
    color: darkslategrey;
    margin: 1px 4px 6px 4px;
}

.DoubleSmallBlockHead {
    padding: 2px 0 0 4px;
    height: 21px;
    margin: -7px -7px 2px -7px;
    background-color: #6c757d4d;
    border-radius: 5px 5px 0 0;
}

.DSBlockHeadBtn {
    position: absolute;
    top: 4px;
    right: 4px;
}

.DoubleSmallBlockAvatar {
    height: 80px;
    width: 80px;
    overflow: hidden;
    border-radius: 40px;
    cursor: pointer;
    margin-left: calc(50% - 40px);
    margin-top: 6px;
    margin-bottom: 8px;
}

.DoubleSmallBlockAvatar img {
    width: 100%;
    height: 100%;
}

.DoubleSmallBlock u {
    text-decoration: none;
    color: var(--input-border-color);
}

.ProjectMABlock {
    padding-left: 16%;
    line-height: 1.6;
    margin-bottom: 6px;
}

.ProjectFlagAddDesc {
    font-size: 9px;
    margin-left: 2px;
    color: var(--object-author-color);
}

.ProjectFlagDescBlock {
    height: 150px;
    width: 100%;
    overflow-y: auto;
}

.ProjectFlagDescItem {
    padding-left: 22px;
    padding-top: 1px;
    position: relative;
    min-height: 22px;
    background-color: #0b0b0b40;
    border: 1px solid #3e3e3ed1;
    border-radius: 5px;
    font-size: 11px;
    margin-top: 5px;
    cursor: default;
}

.ProjectFlagDescItem img {
    position: absolute;
    left: 4px;
    top: 5px;
    width: 13px;
    opacity: 0.8;
}

.ProjectFlagDescMenu {
    position: absolute;
    top: 4px;
    right: 5px;
    font-size: 12px;
    color: var(--object-author-hightlight);
}

.ProjectAddFlagBtn {
    font-size: 12px;
    margin-left: 3px;
    color: var(--object-author-color);
}

.ProjectAccessCheckbox {
    margin-left: 15px;
    width: 10px;
    margin-top: 7px;
}

.ProjectStandardFlagItem {
    font-size: 12px;
    border: 1px solid var(--input-border-color);
    border-radius: 12px 6px 6px 12px;
    padding: 4px 8px;
    margin: 5px 9px 3px 0;
    display: inline-block;
    background-color: #3a76802e;
}

.ProjectStandardFlagItem i {
    font-size: 10px;
    margin-right: 6px;
}

.PSFContainer {
    padding-left: 6px;
}

.ProjectItemContextMenu {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 20%;
    height: 100%;
    background-color: var(--object-bg-color);
    color: var(--input-color);
    box-shadow: -3px 0 0 #ffffff1f;
    opacity: 0.85;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
}

.ProjectItemContextMenu i {
    margin-left: 10px;
}

.ProjectMemberContextMenu {
    font-size: 11px!important;
}

.ProjectMembershipAccept, .ProjectMembershipDecline {
    width: 90%;
    float: left;
    text-align: center;
    font-weight: 500;
    margin: 5%;
    font-size: 12px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    background: var(--info);
    color: var(--input-color);
}

.ProjectMembershipDecline {
    background: var(--danger);
}

.DoubleMiddleBlock{
    width: 48%!important;
}

.DoubleMiddleBlock input[type=text] {
    width: 48%!important;
}

.DoubleMiddleBlock .ProjectFlagDescBlock {
    width: 50%!important;
}

.ProjectFilterPresetBlock {
    width: 49%;
    height: calc(100% - 21px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 21px;
    right: 0;
    padding: 2px 3px 3px 5px;
    text-align: center;
    border-left: 1px dashed var(--input-placeholder);
}

.ProjectPresetItem {
    text-align: left;
    position: relative;
    border-radius: 4px;
    background-color: var(--input-bg-hover-color);
    padding: 3px 3px 3px 4px;
    color: var(--input-color);
    margin: 1px 4px 6px 4px;
}

.ProjectPresetFilterBtn {
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 15px;
    color: burlywood;
}

.ProjectPresetFilterBtn.fa-times {

}

.ProjectPresetFilterBtn.fa-thumbs-up, .ProjectPresetFilterBtn.fa-exclamation-circle {
    color: yellowgreen;
}

.ProjectPresetFilterBtn.fa-thumbs-down, .ProjectPresetFilterBtn.fa-times-circle {
    color: var(--danger);
}

.ProjectPresetFilterBtn.fa-meh, .ProjectPresetFilterBtn.fa-question-circle {
    color: var(--input-color);
}

#ProfileTagsEditorIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}





