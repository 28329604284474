.ObjectEditorBlock {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--object-bg-color);
}

.CloseButton {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 25px;
    /*z-index: 101;*/
}

.HeadBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #2f2f2f;
    cursor: default;
    color: var(--input-color);
    padding: 4px 15px;

}

.EditorBody {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    bottom: 20px;
    background-color: #1b1b1bc4;
}

.EditorBlock {
    position: relative;
    width: 20%;
    float: left;
    height: 100%;
    padding: 10px 15px;
    overflow-y: scroll;
    background-color: var(--object-bg-color);
}

.EditorActivators {
    width: 290px;
    height: 100%;
    padding: 10px 15px;
    overflow-y: scroll;
}

.EditorOptions {
    width: calc(60% - 290px);
}

.EditorFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: #2f2f2f;
    box-shadow: 0 -1px 7px #00000010, inset 0 -4px 8px #00000030;
    cursor: default;
}

.ItemShortMenu {
    position: absolute;
    top: 0;
    right: 0;
    /*min-width: 20%;*/
    height: 100%;
    background-color: var(--object-bg-color);
    color: var(--input-color);
    box-shadow: -3px 0 0 #ffffff1f;
    opacity: 0.85;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
}

.ItemShortMenu i {
    margin-left: 10px;
}

.SpeekMenuIcon {
    top: 8px!important;
}

.SpeekShortMenu {
    padding-top: 6px;
}

.EditorAddButton {
    font-size: 12px;
    position: absolute;
    right: 21px;
    top: 15px;
}


.EditorOptions .MediaTagsEditorIcon {
    right: 20px;
    top: 12px;
    font-size: 14px;
}




