#OptionsBar {
    padding: .75rem 16px;
    margin-bottom: 5px;
    color: #c6c4c0;
    background-color: rgba(35, 119, 107, 0.25);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.headBtn {
    border: 1px solid var(--input-border-color);
    padding: 5px 12px;
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 10px;
    background-color: var(--input-placeholder);
    color: var(--input-color);
    display: inline-block;
}

.headBtn i {
    margin-right: 4px;
}

