.ImportStructureContainer {
    font-size: 12px;
    width: 70%;
}

.ImportStructureBlock {
    width: 100%;
    min-height: 50px;
    border: 0.5px dashed #ffeb3b;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: left;
    padding: 5px;
    overflow: hidden;
    line-height: 1.5;
}

.ImportStructureBlock b {
    color: #ffc107;
}

.ImportStructureBlock .likeBtn {
    margin-right: 5px;
}

.ISObjectItem {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 5px 5px 0 0;
    float: left;
    position: relative;
}

.ISObjectItem i:first-of-type {
    width: inherit;
    height: inherit;
    font-size: 50px;
    color: grey;
    padding: calc(50% - 25px);
}

.ISButton {
    width: 100%;
    border: 1px solid crimson;
    padding: 6px 0;
    border-radius: 5px;
    margin: 20px 0;
    text-align: center;
}

.ISButton i {
    margin-right: 5px;
}

.ISGreenBorder {
    border: 1px solid green!important;
}

.ISTopRight {
    position: absolute;
    right: 2px;
    top: 2px;
    margin-right: 0!important;
}

.ISDanger {
    color: crimson!important;
}
















