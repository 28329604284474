.messageBlock {
    padding: 10px;
    background-color: #2f6b5796;
    margin: 20px;
    text-align: center;
    color: var(--input-color);
}

.invitationAvatarBlock {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    margin: 2px 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid var(--input-border-color);
}

.invitationAvatarBlock img {
    width: 100%;
}




