#Header {
    background-color: #0000008c;
    height: 70px;
    align-items: center;
}

#MainLogo {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 10px;
    color: #9E9E9E;
    display: inline-block;
    text-align: center;
}

#LogoImg {
    height: 43px;
    float: left;
    margin-bottom: -8px;
}

#MainMenu {
    display: inline-block;
    height: 100%;
    float: right;
}

.MainMenuItem {
    color: #cdcdcd;
    text-decoration: none;
    font-size: 13px;
    margin: 27px 21px 27px 10px;
    display: inline-block;
    position: relative;
}

.MainMenuItem i {
    margin-right: 5px;
}

.MMPendingAlert {
    color: var(--object-author-color);
    font-size: 9px;
    position: absolute;
}

.MMNotificationLabel {
    position: absolute;
    top: -11px;
    right: -16px;
    background: rgba(184, 134, 11, 0.76);
    padding: 2px 7px;
    border-radius: 25px;
}
