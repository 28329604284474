.UserListBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*justify-content: space-evenly;*/
}

.UserListItem {
    margin: 5px;
    border: 2px solid #0000006b;
    border-radius: 3px;
    padding: 5px;
    position: relative;
    width: calc(50% - 10px);
    height: 85px;
    cursor: pointer;
    background-color: rgba(138, 138, 138, 0.25);
}

.UserListItem:hover {
    box-shadow: inset 0 0 20px #b5b5b56b;
}

.UserListItem .fa-bell {
    color: goldenrod;
}

.UserItemLeft {
    width: 80px;
    height: 100%;
    float: left;
}

.UserItemRight {
    width: calc(100% - 100px);
    height: 100%;
    float: left;
    line-height: 1.5;
}

.UserIconBlock {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 40px;
}

.UserIconBlock img {
    width: 100%;
    height: 100%;
}

.UserItemName {
    font-weight: 700;
}

.UserItemLastVisit {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 100;
}

.UserItemRegistered {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 100;
}

.UserItemOnline {
    color: limegreen;
    position: absolute;
    top: 3px;
    left: 67px;
    font-size: 10px;
    margin: 0!important;
}

.UserItemOnline i {
    margin-right: 1px;
}



