.LSIconBlock {
    width: 20px;
    height: 20px;
    padding-top: 3px;
    float: left;
    cursor: pointer;
    color: var(--info);
}

.LSSelectBlock {
    position: absolute;
    background: var(--input-bg-hover-color);
    height: 260px;
    z-index: 1;
}

.LSHeader {
    height: 30px;
    padding: 5px;
    border-bottom: 1px solid #15151561;
    color: var(--btn-bg-color);
}

.LSHeader .closeButton {
    font-size: 16px;
    right: -5px;
    top: 7px;
}

.LSItemList {
    overflow-y: scroll;
    height: 230px;
    padding-top: 6px;
    box-shadow: inset 0px 8px 16px 0px #00000033;
}

.LSItem {
    width: 100%;
    cursor: pointer;
    padding: 0 10px;
}

.LSItem:hover {
    background: var(--input-placeholder);
    color: var(--input-color);
}

.LSItemFlag {
    float: left;
    margin-right: 10px;
    width: 20px;
    text-align: right;

}

input.LSSearchInput {
    width: calc(100% - 23px);
    background: var(--input-bg-color);
    border: 1px solid var(--input-placeholder);
    margin: 0;
    color: var(--input-color);
}



