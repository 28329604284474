
.ObjectLIInfoBlock {
    position: absolute;
    left: -7px;
    top: 3px;
    font-size: 10px;
}

.ObjectLIInfoBlock i {
    margin-left: 12px;
    margin-right: 2px;
    color: #ffffff8a;
}