.ObjectSelectorContainer {
    width: 350px;
    min-height: 400px;
    max-height: 80%;
    background-color: var(--object-bg-color);
    border-radius: 4px;
    font-size: 14px;
    padding: 15px;
    overflow-y: scroll;
    border: 1px solid var(--input-bg-blue);
}

.ObjectSelectorContainer b {
    font-size: 16px;
}

.ObjectSelectorContainer .closeButton {
    font-size: 15px;
    top: 17px;
}

.OSItem {
    background-color: var(--input-bg-color);
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--input-bg-blue);
}

.OSAvatar {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 7px;
    background-color: var(--input-placeholder);
}

.OSAvatar i {
    margin: 17px 17px 17px 20px;
    font-size: 16px;
}

.OSAvatar .fa-users {
    margin: 17px;
}

.OSSearch {
    background: var(--input-bg-color);
    border: 1px solid var(--input-placeholder);
    border-radius: 4px;
    margin: 0 0 0 10px;
    color: var(--input-color);
}










