.DoubleLeftSide {
    float: left;
    width: 30%;
    background: var(--input-bg-color);
    margin: 5px;
    border: solid 1px var(--input-border-color);
    color: var(--input-color);
    border-radius: 3px;
    height: fit-content;
    line-height: 1.5;
}

.DoubleLeftHead {
    text-align: center;
    padding: 20px;
    margin: 5px;
    border-bottom: solid 1px var(--input-border-color);
    position: relative;
}

.DoubleAvatar {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50px;
    display: inline-block;
    cursor: pointer;
}

.DoubleAvatar img {
    width: 100%;
    height: 100%;
}

#ProfileAuthQRCode {
    position: absolute;
    top: 10px;
    right: 10px;
}

#ProfileAddFilter {
    position: absolute;
    top: 10px;
    left: 10px;
}

.DoubleLeftBlock {
    padding: 7px;
    margin: 5px;
    font-size: 12px;
    border-bottom: solid 1px var(--input-border-color);
    color: var(--input-color);
}

.DoubleLeftBlock i {
    margin-left: 5px;
    margin-right: 6px;
    font-size: 16px;
    width: 25px;
}



/*------RIGHT PART--------*/
.DoubleRightSide {
    float: left;
    width: calc(70% - 20px);
    background: var(--input-bg-color);
    border: solid 1px var(--input-border-color);
    color: var(--input-color);
    border-radius: 3px;
    margin: 5px;
    height: fit-content;
    line-height: 1.5;

}

.DoubleRightBlock {
    padding: 20px;
    margin: 5px;
    border-bottom: solid 1px var(--input-border-color);
    overflow: hidden;
}

.DoubleInputBlock {
    width: 50%;
    float: left;
    padding: 20px 0 0 0;
    font-size: 13px;
}

.DoubleInput {
    width: 90%;
    margin-top: 6px;
    background: var(--input-bg-hover-color);
    border: 1px solid var(--input-border-color);
    padding: 6px;
    color: var(--input-color);
    border-radius: 5px;
    font-size: 13px;
}

.DoubleFullWidth {
    width: 100%!important;
}

.DoubleFullWidthInput {
    width: 95%!important;
}

.DoubleButton {
    width: 25%;
    float: left;
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
    font-size: 13px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    background: darkcyan;
    color: var(--input-color);
}

.DoubleIBRightText {
    font-size: 12px;
    float: right;
    margin-right: 10%;
}


/*OBJECT SELECTOR*/
.ObjectSelectorBlock {
    width: 50px;
    height: 50px;
    margin: 6px 10px 10px 0;
    text-align: center;
    padding: 0;
    overflow: hidden;
    float: left;
    border: 1px solid var(--input-border-color);
}

.ObjectSelectorBlock i {
    margin-top: 16px;
}

.ObjectSelectorText {
    float: left;
    height: 50px;
    margin: 6px 0 10px 0;
    padding-top: 13px;
}

.DoubleTextIcon {
    margin-right: 7px;
    font-size: 15px;
}

.DoubleInputBlock .DoubleButton {
    width: 50%!important;
}




