.LPContainer {
    position: fixed;
    width: 100%;
    height: calc(100% - 70px);
    top: 70px;
    left: 0;
}

.LPSignInBlock {
    position: absolute;
    left: 13%;
    top: 25%;
    background: rgba(35, 119, 107, 0.25);
    padding: 4%;
    width: 30%;
    border-radius: 3px;
}

.LPSignUpBlock {
    position: absolute;
    right: 13%;
    top: 25%;
    background: rgba(35, 119, 107, 0.25);
    padding: 4%;
    width: 30%;
    border-radius: 3px;
}

.LPInputBlock {
    margin-top: 10px;
}

.LPInput {
    background-color: var(--input-bg-color);
    border: 0;
    padding: 5px;
    margin: 0;
    height: 100%;
    color: var(--input-color);
    width: 100%;
}

.LPInputHeader {
    display: inline-block;
    border: 1px solid var(--input-border-color);
    border-radius: 5px 0 0 5px;
    border-right: 0;
    padding: 5px;
    font-size: 12px;
    height: 30px;
    overflow: hidden;
    background-color: #0000008c;
    width: 23px;
    color: var(--input-color);
}

.LPInputBody {
    overflow: hidden;
    display: inline-block;
    border: 1px solid var(--input-border-color);
    border-radius: 0 5px 5px 0;
    font-size: 12px;
    height: 30px;
    width: calc(100% - 24px);
}

.LPButton {
    margin-top: 30px;
    width: 100%;
    border: 1px solid;
    font-size: 12px;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--object-bg-color);
    border-color: var(--input-border-color);
}

#generatePassword {
    float: right;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: -20px;
}

