.leaflet-container {
    height: 400px;
    width: 800px;
}

.MapSlider {
    position: absolute;
    top: calc(50% + 225px);
    left: calc(50% - 400px);
    width: 600px;
}

.MapSaveButton {
    position: absolute;
    top: calc(50% + 217px);
    right: calc(50% - 400px);
    font-size: 12px;
    border: 1px solid var(--input-color);
    color: var(--input-color);
    font-weight: 100;
    padding: 5px 10px;
    max-width: 220px;
    border-radius: 2px;
}

.MapPositionFromLabel {
    border: none;
    top: calc(50% + 218px);
    font-size: 10px;
    right: calc(50% - 418px);
}

.MapSaveButton i {
    margin-right: 7px;
}

.input-range__track--active {
    background: #00b1e6!important;
}

.input-range__slider {
    background: #c9ecf5!important;
}

.input-range__label-container {
    font-size: 12px!important;
    font-weight: 400!important;
    color: var(--input-color)!important;
}

.input-range__track {
    /*background: var(--btn-bg-color)!important;*/
    height: 1px!important;
}

.input-range__slider {
    height: 12px!important;
    margin-left: -6px!important;
    margin-top: -6px!important;
    width: 12px!important;
}

.input-range__label--value {
    top: -1.5rem!important;
}

.input-range__label--value .input-range__label-container {
    left: -50%!important;
}

.input-range__label--min, .input-range__label--max {
    bottom: -0.9rem!important;
}

.input-range__label-container {
    left: unset!important;
}

.MapPreviewWrapper {
    width: 100%;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    border: 1px solid var(--input-border-color);
}








