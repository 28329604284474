.ProgressContainer {
    position: fixed;
    left: calc(50% - 200px);
    top: calc(50% - 80px);
    width: 400px;
    height: 160px;
    padding: 20px;
    border: 1px solid #f5f5f4;
    border-radius: 3px;
    z-index: 500;
    background-color: white;
    box-shadow: 2px 3px 5px #0000002b;
    color: #475466;
}

.ProgressBG {
    background: #f9f9f9;
    width: 355px;
}

.ProgressDone {
    background: deepskyblue;
    width: 5px;
    border-right: 2px solid #e8e8e8!important;
}

.ProgressDone, .ProgressBG {
    height: 50px;
    margin-top: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    position: absolute;
    top: 45px;
    left: 20px;
}

