.ActivatorContainer {
    color: var(--input-color);
    border: 1px solid var(--input-border-color);
    width: 257px;
    overflow: hidden;
    border-radius: 5px;
    background-color: var(--btn-bg-color);
    margin-bottom: 10px;
    margin-top: 6px;
    position: relative;
    line-height: 1.5;
    min-height: 100px;
}

.ActivatorContentBlock {
    border-bottom: 1px dashed var(--input-border-color);
    overflow: hidden;
    background: var(--input-bg-color);
}

.ActivatorInfoBlock {
    font-size: 12px;
    float: left;
    padding: 10px 0;
    max-width: 187px;
}

.ActivatorCreatorAvatar {
    float: left;
    height: 35px !important;
    width: auto !important;
    border-radius: 3px;
    border: 1px solid #9E9E9E;
    margin-right: 7px;
    cursor: pointer;
}

.ActivatorCreatorBlock {
    overflow: hidden;
    padding: 3px;
    font-size: 11px;
    width: 100%;
}

.ActivatorMenuIcon {
    position: absolute;
    top: 4px;
    right: 5px;
    font-size: 12px;
    color: var(--object-author-hightlight);
}

.ActivatorSightImg {
    height: 255px;
    width: auto;
    cursor: zoom-in;
    display: block;
}

.ActivatorLogoImg {
    font-size: 50px;
    width: 50px;
    text-align: center;
    float: left;
    margin: 10px 10px 10px 5px;
    color: var(--input-placeholder);
}

.ActivatorLogoImg.fa-barcode-read {
    font-size: 35px!important;
}

.ActivatorLogoImg.fa-broadcast-tower {
    font-size: 30px;
    color: var(--input-border-color);
}

.ActivatorLogoImg.fa-map-marked-alt {
    font-size: 30px;
    color: var(--input-border-color);
}

/*Sight viewer*/
.SightViewerContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: #1b1b1bc4;
}

.SightViewerImg {
    width: 500px;
    height: 500px;
    display: block;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 250px);
    border-radius: 4px;
    box-shadow: 0 0 0 5px #0000000d;
}

.sightViewStatisticBlock {
    position: absolute;
    bottom: calc(50% - 245px);
    right: calc(50% - 245px);
    background-color: #3a3a3a7a;
    border-radius: 5px;
    padding: 7px 10px;
    color: #ffffff8f;
    font-size: 12px;
    cursor: default;
}

.sightViewStatisticBlock i {
    margin-left: 10px;
    margin-right: 2px;
    font-size: 12px;
}

.sightViewStatisticBlock i:first-child {
    margin-left: 0;
}

.ActivatorTypeIcon {
    position: absolute!important;
    right: 10px;
    bottom: 12px;
    font-size: 15px;
    color: white;
}

