.PopupMenuContainer {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 50px;
    width: 100%;
    background-color: var(--object-bg-color);
    color: var(--object-author-color);
    box-shadow: -4px 0 14px #000000bf;
    opacity: 0.9;
    padding: 15px 20px;
    font-size: 12px;
    z-index: 11;
}

.PopupMenuContainer div {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    color: #c8c8c8;
}

.PopupMenuContainer div:last-child {
    margin-bottom: 0;
}

.PopupMenuContainer div i {
    position: absolute;
    top: 2px;
    left: 0;
}

















