.BackupItem {
    font-size: 12px;
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    padding: 4px 8px;
    margin: 5px 9px 3px 0;
    background-color: rgba(58,118,128,.18);
}

.BackupItem i {
    margin-right: 5px;
}

.BackupLink {
    text-decoration: none;
}

.TagBlock {
    position: relative;
    display: flex;
    background-color: var(--input-bg-color);
    border: 1px solid var(--object-bg-color);
    border-radius: 4px;
    overflow: hidden;
    margin: 3px 2px;
}

.TagName {
    background-color: var(--object-author-hightlight);
    border-right: 1px solid var(--object-author-hightlight);
    padding: 3px 5px;
    font-weight: bold;
}

.TagValue {
    padding: 3px 5px;
}

